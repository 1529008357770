import React, { useState } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { ChartType } from "../../api/chartData";
import {
  FormControl,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { getVariableList } from "../../redux/Variables/thunks";
import { changeChart, clearChartData } from "../../redux/Charts/actions";
import { getCohortsList, saveCohortChanges } from "../../redux/Cohorts/thunks";
import {
  changeFilter,
  cohortAdd,
  removeVariable,
  toggleSelected,
} from "../../redux/Cohorts/actions";
import { connect } from "react-redux";
import SunburstChart from "../SunburstChart";
import SplitSunburst from "./SplitSunburst";
import SurvivalChart from "../SurvivalChart";
import SankeyChart from "../SankeyChart";
import PopupChart from "./PopupChart";
import Help from "../Help/Help";
import SampleHelp from "../Help/SampleHelp";
import {
  SankeyHelp,
  OverallSurvivalHelp,
  ProgressionFreeSurvivalHelp,
  ZoomSunburstHelp,
} from "../Help/HelpStrings";
import { ChartLayoutHelp } from "../Help/HelpStrings";
import ScatterPlotMatrix from "../ScatterPlotMatrix";
import matrixData from '../../data/cohortFields.json';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  paperFullHeight: {
    height: "100%",
    minHeight: "100%",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0,0,0,.4)",
      borderRadius: "10px",
    },
  },
  charts: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  fullHeight: {
    flexFlow: "column",
    alignItems: "stretch",
    height: "100%",
  },
  outputFullHeight: {
    flexBasis: "calc(100vh - 64px + 8px - 124px)",
    overflow: "hidden",
  },
  heightAuto: {
    flex: "0 1 auto",
  },
  outputButton: {
    borderRadius: "5rem",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(8),
    minWidth: "48px",
  },
  formControl: {
    minWidth: 300,
    maxWidth: 300,
  },
}));

const ChartsLayout = (props) => {
  const classes = useStyles();

  const [popupCharts2, setPopupCharts] = useState({ open: false, name: null });

  const {
    chart,
    changeChart,
    cohorts,
    toggleSelected,
    sunburstData,
    zoomSunburstData,
    sankeyData,
    popupCharts,
    survivalData,
    progressionFreeSurvivalData,
    correlationMatrixData
  } = props;

  const handleChangeMultiple = (event, index) => {
    //console.log(index);
    toggleSelected(index.props.value);
  };

  const handlePopupCharts = (node) => {
    setPopupCharts((s) => {
      if (s.open && s.name === node.name) {
        return { open: false, name: null };
      } else {
        return { open: true, name: node.name, ...node };
      }
    });
  };

  const loadingBox = (
    <Box
      className={classes.loadingBox}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );

  // useEffect(() => {
  //   if (selectedCohorts && selectedCohorts.length === 0) {
  //     if (cohorts && cohorts.length > 0) {
  //       console.log("toggle Selection");
  //       toggleSelected(cohorts[0].uid);
  //     }
  //   }
  // }, []);

  return (
    <Grid container spacing={2} className={classes.fullHeight}>
      <Grid item xs={12} className={classes.heightAuto}>
        <Paper className={classes.paper}>
          <Help content={<ChartLayoutHelp />} />
          <Grid container spacing={2}>
            <Grid item>
              <Grid container spacing={2} justify="space-between">
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={cohorts}
                      onChange={handleChangeMultiple}
                      input={<Input />}
                      renderValue={(selected) => {
                        const selectTitles = [];
                        //console.log(selected);
                        selected.map((s) => {
                          if (s.selected) {
                            selectTitles.push(s.name);
                          }
                          return null;
                        });
                        return selectTitles.join(", ");
                      }}
                    >
                      {cohorts.map((c) => (
                        <MenuItem key={c.uid} value={c}>
                          <Checkbox
                            size="small"
                            checked={
                              cohorts.filter((s) => {
                                // console.log(s, c.uid, s.uid);
                                return s.uid === c.uid;
                              })[0].selected
                            }
                          />
                          <ListItemText primary={c.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color={chart === ChartType.sankey ? "primary" : "default"}
                className={classes.outputButton}
                onClick={() => {
                  changeChart(ChartType.sankey);
                }}
              >
                Sankey
              </Button>

              <Button
                variant="contained"
                color={
                  chart === ChartType.zoom_sunburst ? "primary" : "default"
                }
                className={classes.outputButton}
                onClick={() => {
                  changeChart(ChartType.zoom_sunburst);
                }}
              >
                Sunburst
              </Button>

              {/* <Button
                variant="contained"
                color={chart === ChartType.sunburst ? "primary" : "default"}
                className={classes.outputButton}
                onClick={() => {
                  changeChart(ChartType.sunburst);
                }}
              >
                Sunburst2
              </Button> */}

              <Button
                variant="contained"
                color={
                  chart === ChartType.progression_free_survival
                    ? "primary"
                    : "default"
                }
                className={classes.outputButton}
                onClick={() => {
                  changeChart(ChartType.progression_free_survival);
                }}
              >
                PFS
              </Button>
              <Button
                variant="contained"
                mt={2}
                color={
                  chart === ChartType.overall_survival ? "primary" : "default"
                }
                className={classes.outputButton}
                onClick={() => {
                  changeChart(ChartType.overall_survival);
                }}
              >
                OS
              </Button>
              <Button
                variant="contained"
                mt={2}
                color={
                  chart === ChartType.correlation_matrix ? "primary" : "default"
                }
                className={classes.outputButton}
                onClick={() => {
                  changeChart(ChartType.correlation_matrix);
                }}
              >
                Matrix
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} className={classes.outputFullHeight}>
        <Paper className={clsx(classes.paper, classes.paperFullHeight)}>
          <div className={classes.charts}>
            {chart === ChartType.sunburst ? (
              !sunburstData.loading ? (
                <SunburstChart data={sunburstData.data} />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.zoom_sunburst ? (
              !zoomSunburstData.loading ? (
                <SplitSunburst
                  data={zoomSunburstData.data}
                  popupCharts={popupCharts}
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.overall_survival ? (
              !survivalData.loading ? (
                <SurvivalChart
                  data={survivalData.data}
                  xLabel="Months"
                  yLabel="Percent"
                  title="Overall Survival for Patients in the Selected Cohorts"
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.progression_free_survival ? (
              !progressionFreeSurvivalData.loading ? (
                <SurvivalChart
                  data={progressionFreeSurvivalData.data}
                  xLabel="Months"
                  yLabel="Percent"
                  title="Progression Free Survival for Patients in the Selected Cohorts"
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.sankey ? (
              !sankeyData.loading ? (
                <SankeyChart
                  data={sankeyData.data}
                  togglePopup={handlePopupCharts}
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.correlation_matrix ? (
              !correlationMatrixData.loading ? (
                <ScatterPlotMatrix
                  data={correlationMatrixData.data}
                />
              ) : (
                loadingBox
              )
            ) : null}
          </div>
          <PopupChart
            open={popupCharts2.open}
            node={popupCharts2}
            toggle={handlePopupCharts}
            data={popupCharts.popupData}
          />
          <Help
            content={
              chart === ChartType.sankey ? (
                <SankeyHelp />
              ) : chart === ChartType.overall_survival ? (
                <OverallSurvivalHelp />
              ) : chart === ChartType.progression_free_survival ? (
                <ProgressionFreeSurvivalHelp />
              ) : chart === ChartType.zoom_sunburst ? (
                <ZoomSunburstHelp />
              ) : (
                <SampleHelp />
              )
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  ...state.charts,
  ...state.cohorts,
});

const mapDispatch = {
  getVariableList,
  changeChart,
  getCohortsList,
  removeVariable,
  changeFilter,
  clearChartData,
  cohortAdd,
  saveCohortChanges,
  toggleSelected,
};

export default connect(mapStateToProps, mapDispatch)(ChartsLayout);
