import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import { makeStyles, useTheme } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import * as _ from "lodash";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "inline-block",
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

function ZoomSunburst({ data, colors, nodeSelectedCallback }) {
  const classes = useStyles();
  // console.log(data);
  const ref = useRef();
  const refBread = useRef();

  const theme = useTheme();
  if (!colors) colors = theme.charts;

  const breadcrumbHeight = 30;
  const breadcrumbWidth = 100;

  useEffect(() => {
    const width = 800;
    const height = width;
    const maxRadius = Math.min(width, height) * 0.55 - 5;
    const font_size = "1.4em"

    const formatNumber = d3.format(",d");

    const x = d3
      .scaleLinear()
      .range([0, 2 * Math.PI])
      .clamp(true);

    const y = d3.scaleSqrt().range([-100, maxRadius]);

    //const color = d3.scaleOrdinal(colors);
    const color = d3
      .scaleOrdinal()
      .domain(["home", "product", "search", "account", "other", "end"])
      .range(theme.charts);

    const partition = d3.partition();

    const arc1 = d3
      .arc()
      .startAngle((d) => x(d.x0))
      .endAngle((d) => x(d.x1) - 0.01)
      // .padAngle(1 / radius)
      // .padRadius(radius)
      .innerRadius((d, i) => {
        //console.log(i, d.data.name, d.y0, y(d.y0), y(d.y1));
        return Math.max(0, y(d.y0));
      })
      .outerRadius((d) => Math.max(0, y(d.y1)) - 1);

    const middleArcLine = (d) => {
      const halfPi = Math.PI / 2;
      const angles = [x(d.x0) - halfPi, x(d.x1) - halfPi];
      const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);

      const middleAngle = (angles[1] + angles[0]) / 2;

      const invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
      if (invertDirection) {
        angles.reverse();
      }

      const path = d3.path();
      path.arc(0, 0, r, angles[0], angles[1], invertDirection);
      return path.toString();
    };

    const textFits = (d) => {
      const CHAR_SPACE = 20;
      if (!d) {
        return 0;
      }
      const deltaAngle = x(d.x1) - x(d.x0);
      const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);
      const perimeter = r * deltaAngle;

      return d.data.name.length * CHAR_SPACE < perimeter;
    };

    function breadcrumbPoints(d, i) {
      const tipWidth = 10;
      const points = [];
      points.push("0,0");
      points.push(`${breadcrumbWidth},0`);
      points.push(`${breadcrumbWidth + tipWidth},${breadcrumbHeight / 2}`);
      points.push(`${breadcrumbWidth},${breadcrumbHeight}`);
      points.push(`0,${breadcrumbHeight}`);
      if (i > 0) {
        // Leftmost breadcrumb; don't include 6th vertex.
        points.push(`${tipWidth},${breadcrumbHeight / 2}`);
      }
      //console.log(points.join(" "));
      return points.join(" ");
    }

    const drawBreadCrumb = (sequence, percentage) => {
      const svg = d3.select(refBread.current);

      svg.selectAll("text").remove();
      svg
        .attr("viewBox", `0 0 ${breadcrumbWidth * 8} ${breadcrumbHeight}`)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .style("width", "calc(100%)")
        //.style("height", "4%")
        .style("font", "12px sans-serif")
        .style("margin", "5px");

      if (!sequence) {
        svg.selectAll("text").remove();
        svg.selectAll("polygon").remove();
        return;
      }

      const g = svg
        .selectAll("g")
        .data(sequence)
        .join("g")
        .attr("transform", (d, i) => `translate(${i * breadcrumbWidth}, 0)`);

      g.append("polygon")
        .attr("points", breadcrumbPoints)
        .attr("fill", (d) => color(d.data.name))
        .attr("stroke", "white");

      g.append("text")
        .attr("x", (breadcrumbWidth + 10) / 2)
        .attr("y", 15)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .attr("fill", "white")
        .text((d) => d.data.name);

      svg
        .append("text")
        .text(percentage > 0 ? percentage + "%" : "")
        .attr("x", (sequence.length + 0.5) * breadcrumbWidth)
        .attr("y", breadcrumbHeight / 2)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle");
    };

    function focusOn(d = { x0: 0, x1: 1, y0: 0, y1: 1 }) {
      // Reset to top-level if no data point specified
      const svg = d3.select(ref.current);
      const transition = svg
        .transition()
        .duration(750)
        .tween("scale", () => {
          const xd = d3.interpolate(x.domain(), [d.x0, d.x1]); //,
          //yd = d3.interpolate(y.domain(), [d.y0, 1]);
          return (t) => {
            x.domain(xd(t));
            //y.domain(yd(t));
          };
        });

      transition
        .selectAll("path.main-arc")
        .attrTween("d", (d) => () => arc1(d));

      transition
        .selectAll("path.hidden-arc")
        .attrTween("d", (d) => () => middleArcLine(d));

      // transition
      //   .selectAll("text")
      //   .attrTween("display", (d) => () => (textFits(d) ? null : "none"));

      moveStackToFront(d);
      console.log(d);

      function moveStackToFront(elD) {
        svg
          .selectAll(".slice")
          .filter((d) => d === elD)
          .each(function (d) {
            this.parentNode.appendChild(this);
            if (d.parent) {
              moveStackToFront(d.parent);
            }
          });
      }
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d) {
      if (!d) {
        return null;
      }
      //console.log(d);
      const halfPi = 0; // Math.PI / 2;
      const angles = [x(d.x0) - halfPi, x(d.x1) - halfPi];
      //const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);

      const xp = (((angles[1] + angles[0]) / 2) * 180) / Math.PI;

      // const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const yp = y((d.y0 + d.y1) / 2); // * maxRadius;
      console.log(xp, yp);
      return `rotate(${xp - 90}) translate(${yp},0) rotate(${
        xp < 180 ? 0 : 180
      })`;
    }

    const draw = async () => {
      //select the current SVG
      const svg = d3.select(ref.current);
      svg.selectAll("text").remove();
      svg.selectAll("*").remove(); //remove all the graphic elements

      svg
        //.style("width", "100vw")

        .attr("viewBox", `-${width / 2} ${-height / 2} ${width} ${height}`)
        // .attr("viewBox", `${width / 2} ${-height / 2} ${width} ${height}`)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .style("height", "calc(100% - 45px)")
        .style("display", "block") //this is required for Safari to work
        //.classed("classes.content", true)
        //.attr("preserveAspectRatio", "xMidYMid")
        .on("click", () => focusOn()); // Reset zoom on canvas click

      const label = svg
        .append("text")
        .attr("id", "percent")
        .attr("text-anchor", "middle")
        .attr("fill", "#888")
        .style("visibility", "hidden");

      label
        .append("tspan")
        .attr("class", "percentage")
        .attr("x", 0)
        .attr("y", 0)
        .attr("dy", "-0.1em")
        .attr("font-size", "3em")
        .text("");

      //console.log(data);
      let root = d3.hierarchy(data);
      // console.log(root);
      root.sum((d) => d.value);

      const slice = svg
        .selectAll("g.slice")
        //.data(partition(root).descendants());
        .data(
          partition(root)
            .descendants()
            .filter((d) => {
              // Don't draw the root node, and for efficiency, filter out nodes that would be too small to see
              return d.depth && d.x1 - d.x0 > 0.001 && d.data.name !== "end";
            })
        );
      slice.exit().remove();

      const element = svg.node();
      element.value = { sequence: [], percentage: 0.0 };

      // label
      //   .append("tspan")
      //   .attr("x", 0)
      //   .attr("y", 0)
      //   .attr("dy", "1.5em")
      //   .attr("font-size", "1.5em")
      //   .text("of visits begin with this sequence");

      const newSlice = slice
        .enter()
        .append("g")
        .attr("class", "slice")
        .on("click", (event, d) => {
          event.stopPropagation();
          focusOn(d);
          const sequence = d.ancestors().reverse().slice(1);
          const percentage = ((100 * d.value) / root.value).toPrecision(3);
          //console.log(sequence);
          if (sequence.length === 0) {
            drawBreadCrumb(undefined, undefined);
          } else {
            drawBreadCrumb(sequence, percentage);
          }
        })
        .on("mouseleave", () => {
          newSlice.attr("fill-opacity", 1);
          label.style("visibility", "hidden");
          // Update the value of this view
          element.value = { sequence: [], percentage: 0.0 };
          element.dispatchEvent(new CustomEvent("input"));
          drawBreadCrumb(undefined, undefined);
        })
        .on("mouseenter", (event, d) => {
          if (nodeSelectedCallback) {
            //console.log(d);
            nodeSelectedCallback(_.cloneDeep(d));
          }
          // Get the ancestors of the current segment, minus the root
          const sequence = d.ancestors().reverse().slice(1);
          // Highlight the ancestors
          newSlice.attr("fill-opacity", (node) =>
            sequence.indexOf(node) >= 0 ? 1.0 : 0.3
          );
          const percentage = ((100 * d.value) / root.value).toFixed(1);
          label
            .style("visibility", null)
            .select(".percentage")
            .text(percentage + "%");
          // Update the value of this view with the currently hovered sequence and percentage
          element.value = { sequence, percentage };
          element.dispatchEvent(new CustomEvent("input"));
          drawBreadCrumb(sequence, percentage);
        });

      newSlice
        .append("title")
        .text((d) => d.data.name + "\n" + formatNumber(d.value));

      newSlice
        .append("path")
        .attr("class", "main-arc")
        .style("fill", (d) => color((d.children ? d : d.parent).data.name))
        .attr("d", arc1);

      newSlice
        .append("path")
        .attr("class", "hidden-arc")
        .attr("id", (_, i) => `hiddenArc${i}`)
        .style("fill", "none")
        .attr("d", middleArcLine);

      // const text = newSlice
      //   .append("text")
      //   .attr("dy", "0.15em")
      //   .attr("text-anchor", "middle")
      //   .attr("font-size", "0.8em")
      //   //.attr("fill-opacity", (d) => +labelVisible(d.current))
      //   .attr("transform", (d) => labelTransform(d))
      //   .text((d) => {
      //     if (d.data.name === "end") {
      //       return null;
      //     }
      //     return d.data.name;
      //   });

      const text = newSlice
        .append("text")
        .attr("font-size", font_size)
        .attr("display", (d) => (textFits(d) ? null : "none"));

      // Add white contour
      text
        .append("textPath")
        .attr("startOffset", "30%")
        .attr("xlink:href", (_, i) => `#hiddenArc${i}`)
        .attr("font-size", font_size)
        .text((d) => {
          if (d.data.name === "end") {
            return "No Data";
          }
          return d.data.name;
        })
        .style("fill", "none")
        .style("stroke", "#fff")
        .style("stroke-width", 5)
        .style("stroke-linejoin", "round");

      text
        .append("textPath")
        .attr("startOffset", "30%")
        .attr("font-size", font_size)
        .attr("xlink:href", (_, i) => `#hiddenArc${i}`)
        .text((d) => {
          if (d.data.name === "end") {
            return "No Data";
          }
          return d.data.name;
        });
    };
    if (data) {
      // console.log("Redraw");
      draw();
    }
  }, [data]);

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <svg
        className="sunburstBreadcrumb"
        ref={refBread}
        style={{ height: "35px" }}
      />
      <svg
        className="zoomsunburst"
        ref={ref}
        style={{ height: "calc(1005-45px)" }}
      />
    </div>
  );
}

export default ZoomSunburst;
