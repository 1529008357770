import React, { useEffect, useRef, useState } from "react";
import { useTheme, Typography } from "@material-ui/core";
import * as d3 from "d3";

export default function SurvivalChart({ data, xLabel, yLabel, title }) {
  //console.log(data);
  const [d3data, setd3data] = useState(null);

  const theme = useTheme();
  const ref = useRef();
  if (!title) {
    title = "add title";
  }

  // console.log(data);

  useEffect(() => {
    function dataTransform(data) {
      if (d3data.length > 0) {
        //find the unique cohorts
        const o = d3data.map((d) => d["name"]);
        const cohorts = o.filter((item, i, ar) => ar.indexOf(item) === i);

        //console.log(cohorts);
        const cData = {};
        for (let i = 0; i < cohorts.length; i++) {
          const item = cohorts[i];
          //console.log(item);
          cData[item] = d3data.filter((x) => x.name === item);
          //now sort on the x value
          cData[item].sort(function (a, b) {
            return a.x - b.x;
          });
        }

        return { cohorts, cData };
      }
      return null;
    }

    const draw = () => {
      const margin = { left: 40, right: 20, top: 40, bottom: 30 };
      const width = 600 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;
      const svg = d3
        .select(ref.current)
        .style("width", "100%")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        );
      svg.selectAll("*").remove();

      const groupedData = d3
        .group(d3data, (d) => d.name + " (" + d.n_persons + ")");
      const sumdata = Array.from(groupedData, ([key, value]) => ({key, value}))
      const res = sumdata.map((d) => d.key);
      const color = d3.scaleOrdinal().domain(res).range(theme.charts);

      // Add X axis
      const x = d3
        .scaleLinear()
        .domain([d3.min(d3data, (d) => d.x), d3.max(d3data, (d) => d.x)])
        .range([0, width]);
      svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${height + margin.top})`)
        .call(d3.axisBottom(x))
        .call((g) =>
          g
            .selectAll(".tick line")
            .clone()
            .attr("y2", -height)
            .attr("stroke-dasharray", "4 2")
            .attr("stroke-opacity", 0.1)
        );
      svg
        .append("text")
        .style("font", "10px sans-serif")
        .attr(
          "transform",
          `translate(${(width + margin.left + margin.right) / 2}, ${
            height + margin.top + margin.bottom
          })`
        )
        .style("text-anchor", "middle")
        .text(data.x_units);

      // Add Y axis
      const y = d3
        .scaleLinear()
        .domain([0, d3.max(d3data, (d) => d.y)])
        .range([height, 0]);
      svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .call(d3.axisLeft(y))
        .call((g) =>
          g
            .selectAll(".tick line")
            .clone()
            .attr("x2", width)
            .attr("stroke-dasharray", "4 2")
            .attr("stroke-opacity", 0.1)
        );
      svg
        .append("text")
        .style("font", "10px sans-serif")
        .attr("transform", "rotate(-90)")
        .attr("y", 10)
        .attr("x", 0 - (height + margin.top + margin.bottom) / 2)
        .style("text-anchor", "middle")
        .text(data.y_units);

      // // Add Y2 axis
      // const y2 = d3
      //   .scaleLinear()
      //   .domain([0, d3.max(d3data, (d) => d.y2)])
      //   .range([height, 0]);
      // svg
      //   .append("g")
      //   .attr("transform", `translate(${margin.left + width}, ${margin.top})`)
      //   .call(d3.axisRight(y2));
      // svg
      //   .append("text")
      //   .style("font", "10px sans-serif")
      //   .attr("transform", "rotate(-90)")
      //   .attr("y", width - margin.left - 10)
      //   .attr("x", 0 - (height + margin.top + margin.bottom) / 2)
      //   .style("text-anchor", "middle")
      //   .text(data.y2_units);

      const yAxisLabel = svg
        .append("g")
        .attr(
          "transform",
          "translate(" + 5 + ", " + (height + margin.top + margin.top) / 2 + ")"
        );

      yAxisLabel
        .append("text")
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "central")
        .attr("font-size", 10)
        .attr("transform", "rotate(270)")
        .text(yLabel);

      const xAxisLabel = svg
        .append("g")
        .attr(
          "transform",
          "translate(" +
            (width + margin.left + margin.left) / 2 +
            ", " +
            (height + margin.top + margin.bottom) +
            ")"
        );

      xAxisLabel
        .append("text")
        .attr("font-size", 10)
        .attr("text-anchor", "middle")
        .text(xLabel);

      const legendBox = svg
        .append("g")
        .attr("transform", `translate(${width - 110},50)`);
      legendBox
        .append("rect")
        .attr("width", 140)
        .attr("height", sumdata.length * 25 + 4)
        .attr("x", 0)
        .attr("y", 0)
        .attr("fill", "white")
        .attr("stroke", "gray");

      const legend = svg
        .append("g")
        .attr("transform", `translate(${width - 106},60)`)
        .selectAll(".lines")
        .data(sumdata)
        .enter();

      legend
        .append("rect")
        .attr("width", 14)
        .attr("height", 14)
        .attr("y", (d, i) => 20 * i)
        .attr("fill", (d) => color(d.key));

      legend
        .append("text")
        .attr("y", (d, i) => 20 * i + 10)
        .attr("x", 18)
        .style("font", "10px sans-serif")
        .html((d) => d.key);

      // Lines
      svg
        .selectAll(".lines")
        .data(sumdata)
        .enter()
        .append("path")
        .attr("fill", "none")
        .attr("stroke", (d) => color(d.key))
        .attr("stroke-width", 2)
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .attr("d", (d) => {
          const curve = d3
            .line()
            .x((d) =>
                x(d.x)
            )
            .y((d) =>
                y(d.y)
            )
            .curve(d3.curveStepAfter)
            return curve(d.value);
        });

      const focus = svg
        .append("g")
        .attr("id", "focusLine")
        .append("rect")
        .style("fill", "grey")
        .style("opacity", 0)
        .attr("width", 0.5)
        .attr("height", height);

      const focusText = svg
        .append("g")
        .attr("id", "focusText")
        .append("text")
        .style("font", "10px sans-serif")
        .style("opacity", 0)
        .attr("text-anchor", "top")
        .attr("alignment-baseline", "middle");

      const bisect = d3.bisector((d) => d.x).right;

      function mousemove(event) {
        let m = d3.pointer(event);

        const tipPos = () => {
          return m[0] > width - 100
            ? m[0] - margin.left + 30
            : m[0] + margin.left + 10;
        };

        const value = x.invert(m[0]);
        let i = bisect(d3data, x.invert(m[0]));
        const cData = dataTransform(data);
        let str = "";

        if (cData) {
          for (let j = 0; j < cData.cohorts.length; j++) {
            const a = cData.cData[cData.cohorts[j]];
            let out = a[0].y;
            let people = a[0].y2;
            for (let ii = 0; ii < a.length; ii++) {
              if (a[ii].x > value) {
                break;
              }
              out = a[ii].y;
              people = a[ii].y2;
            }

            str += `<tspan dy="${j + 12}" x="${tipPos()}" fill="${
              theme.charts[j]
            }">${
              cData.cohorts[j] + ": " + out.toFixed(2) + "% (" + people + ")"
            }</tspan>`;
          }
        }

        let selectedData = d3data[i - 1] ? d3data[i - 1] : { x: 0, y: 0 };

        focus
          .style("opacity", 1)
          .attr("x", m[0] + margin.left)
          .attr("y", margin.top);
        focusText
          .style("opacity", 1)
          .html(
            `<tspan dy="0" x="${tipPos()}">Month ${
              selectedData.x
            }</tspan> ${str}`
          )
          .attr("x", tipPos)
          .attr("y", m[1] + margin.top);
      }

      function mouseout() {
        focus.style("opacity", 0);
        focusText.style("opacity", 0);
      }

      if (d3data.length) {
        svg
          .append("rect")
          .attr("fill", "none")
          .style("opacity", 0.2)
          .style("pointer-events", "all")
          .attr("width", width + 1)
          .attr("height", height)
          .attr("transform", `translate(${margin.left}, ${margin.top})`)
          .on("mousemove", mousemove)
          .on("mouseout", mouseout);
      }
    };

    if (d3data) {
      //console.log(d3data);
      draw();
    }
  }, [d3data, data, theme.charts, xLabel, yLabel]);

  useEffect(() => {
    if (data) {
      // console.log(data);
      //console.log(data.length);
      let x = [];
      for (let i = 0; i < data.length; i++) {
        //console.log(data[i]);
        x = [...x, ...data[i].data];
      }
      setd3data(x);
      //console.log(x);
    }
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography align="center" variant="h6">
        {title}
      </Typography>
      <svg className="survival-matrix" ref={ref} />
    </div>
  );
}
