import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function ScatterPlotMatrix({ data }) {
  const ref = useRef();
  const width = 954
  const height = 650
  const padding = 20
  const legendOffset = 20
  const columns = ["age", "survival_rate", "value"]

  useEffect(() => {
    const size = (width - (columns.length + 1) * padding) / columns.length + padding - legendOffset

    const x = columns.map(c => d3.scaleLinear()
      .domain(d3.extent(data, d => d[c]))
      .rangeRound([padding / 2, size - padding / 2]));
    const y = x.map(x => x.copy().range([size - padding / 2, padding / 2]));
    const z = d3.scaleOrdinal()
      .domain(data.map(d => d.cohort))
      .range(d3.schemeCategory10);

    const yAxis = () => {
      const axis = d3.axisLeft()
        .ticks(6)
        .tickSize(-size * columns.length);
      return g => g.selectAll("g").data(y).join("g")
        .attr("transform", (d, i) => `translate(0,${i * size + legendOffset})`)
        .each(function(d) { return d3.select(this).call(axis.scale(d)); })
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll(".tick line").attr("stroke", "#ddd"));
    }

    const xAxis = () => {
      const axis = d3.axisBottom()
        .ticks(6)
        .tickSize(size * columns.length);
      return g => g.selectAll("g").data(x).join("g")
        .attr("transform", (d, i) => `translate(${i * size},${legendOffset})`)
        .each(function(d) { return d3.select(this).call(axis.scale(d)); })
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll(".tick line").attr("stroke", "#ddd"));
    }

    function brush(cell, circle) {
      const brush = d3.brush()
        .extent([[padding / 2, padding / 2], [size - padding / 2, size - padding / 2]])
        .on("start", brushstarted)
        .on("brush", brushed)
        .on("end", brushended);

      cell.call(brush);

      let brushCell;

      // Clear the previously-active brush, if any.
      function brushstarted() {
        if (brushCell !== this) {
          d3.select(brushCell).call(brush.move, null);
          brushCell = this;
        }
      }

      // Highlight the selected circles.
      function brushed(ev, [i, j]) {
        // console.log(event)
        if (ev.selection === null) return;
        const [[x0, y0], [x1, y1]] = ev.selection;
        circle.classed("hidden",
          d => x0 > x[i](d[columns[i]])
            || x1 < x[i](d[columns[i]])
            || y0 > y[j](d[columns[j]])
            || y1 < y[j](d[columns[j]]));
      }

      // If the brush is empty, select all circles.
      function brushended(ev) {
        if (ev.selection !== null) return;
        circle.classed("hidden", false);
      }
    }

    d3.select(ref.current)
      .attr("viewBox", [0, 0, width, height])
      .attr("width", width)
      .attr("height", height)

    const draw = () => {
      const svg = d3.select(ref.current)
        .attr("viewBox", [-padding, 0, width, width]);

      svg.append("style")
        .text(`circle.hidden { fill: #000; fill-opacity: 1; r: 1px; }`);

      svg.append("g")
        .call(xAxis());

      svg.append("g")
        .call(yAxis());

      const cell = svg.append("g")
        .selectAll("g")
        .data(d3.cross(d3.range(columns.length), d3.range(columns.length)))
        .join("g")
        .attr("transform", ([i, j]) => `translate(${i * size},${j * size + legendOffset})`);

      cell.append("rect")
        .attr("fill", "none")
        .attr("stroke", "#aaa")
        .attr("x", padding / 2 + 0.5)
        .attr("y", padding / 2 + 0.5)
        .attr("width", size - padding)
        .attr("height", size - padding);

      cell.each(function([i, j]) {
        d3.select(this).selectAll("circle")
          .data(data.filter(d => !isNaN(d[columns[i]]) && !isNaN(d[columns[j]])))
          .join("circle")
          .attr("cx", d => x[i](d[columns[i]]))
          .attr("cy", d => y[j](d[columns[j]]));
      });

      const circle = cell.selectAll("circle")
        .attr("r", 3.5)
        .attr("fill-opacity", 0.7)
        .attr("fill", d => z(d.cohort));

      cell.call(brush, circle);

      svg.append("g")
        .style("font", "12px sans-serif")
        .style("pointer-events", "none")
        .selectAll("text")
        .data(columns)
        .join("text")
        .attr("transform", (d, i) => `translate(${i * size},${i * size + legendOffset})`)
        .attr("x", padding)
        .attr("y", padding)
        .attr("dy", ".71em")
        .text(d => d);

      const color = d3.scaleOrdinal()
        .domain(data.map(d => d.cohort))
        .range(d3.schemeCategory10);

      const sumdata = d3
        .group(data,(d) => d.cohort)
        .entries()

      const legend = svg
        .append("g")
        .attr("transform", `translate(0,0)`)
        .selectAll(".lines")
        .data(sumdata)
        .enter();

      legend
        .append("rect")
        .attr("width", 14)
        .attr("height", 14)
        .attr("x", (d, i) => 160 * i)
        .attr("fill", (d) => color(d[0]));

      legend
        .append("text")
        .attr("y", 13)
        .attr("x", (d, i) => 160 * i + 20)
        .style("font", "12px sans-serif")
        .html((d) => d[0]);
    }

    if(data) draw();
  }, [data, width, height])

  return (
    <svg className="sankey" ref={ref} />
  )
}

export default ScatterPlotMatrix;